@import "Constants";

.video_wrapper {
  --fs-bar-height: 60px;
  --ar: calc(4 / 3);
  z-index: 10;
  display: flex;
  flex-flow: column;
  left: var(--margin-mini);

  min-width: 100%;
  height: 100%;

  .MuiSvgIcon-root {
    fill: white;
  }

  &.big {
    transition: width 0.3s, height 0.3s;
  }

  &.fs {
    width: 100vw;
    bottom: env(safe-area-inset-bottom, 0px) !important;
    top: $menu_bar_height;
    left: 0;
    border-radius: 0;
    height: 100%;
    transition: none;
    box-shadow: inset 0 8px 6px -8px #000000a1;
    .video_meet {
      aspect-ratio: auto;
      height: calc(100% - var(--fs-bar-height));
    }

    .video_bar {
      position: absolute;
      bottom: env(safe-area-inset-bottom, 0px);
      height: var(--fs-bar-height);
      z-index: 10;
      text-align: center;
      .MuiButtonBase-root {
        margin: 0px 5px;
        padding: 0 5px;
      }
      .MuiSvgIcon-root {
        transform: scale(1.3);
        margin: 0 5px;
      }
    }
  }

  &.big {
    width: 60vw;
    height: calc(var(--bar-height) + 60vw / var(--ar));

  }

  .video_bar {
    height: var(--bar-height);
    box-sizing: border-box;
    padding: 2px;
    width: 100%;
    background-color: black;
    text-align: center;
    .MuiButtonBase-root {
      margin-left: 10px;
    }

  }

  .video_meet {
    position: relative;
    aspect-ratio: 4 / 3;
    width: 100%;
    height: 100%;
    overflow: hidden;


    > iframe {
      display: block;
      position: absolute;
      width: calc(100% + 6px) !important;
      height: calc(100% + 6px) !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      //pointer-events: none;   // in order to disable double-click: toggle film strip
    }
  }
}

