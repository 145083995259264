#welcome {
  font-family: Rubik;
}
#welcome .main {
  width: 100%;
  margin-top: 120px;
  --top-padding: min(20vw, 90px);
  height: calc(100% - 120px);
  background-image: url("/public/img/bg1.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0 0;
  position: relative;
  padding: var(--top-padding) 10px;
  box-sizing: border-box;
}
#welcome .main .avatar {
  position: absolute;
  width: min(33vw, 160px);
  padding-top: min(33vw, 160px);
  top: 0px;
  left: 50%;
  transform: translate(-50%, -60%);
}
#welcome .main .avatar:before, #welcome .main .avatar:after {
  content: "";
  display: block;
  border-radius: 50%;
  position: absolute;
}
#welcome .main .avatar:before {
  padding-top: 30%;
  width: 30%;
  top: 14%;
  background-color: #5CC5C7;
  right: -8%;
}
#welcome .main .avatar:after {
  padding-top: 14%;
  width: 14%;
  bottom: 14%;
  background-color: #2A6DF0;
  left: 0%;
}
#welcome .main .avatar > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
#welcome .main .avatar .outer {
  width: 100%;
  height: 100%;
  box-shadow: 1px 8px 20px rgba(168, 166, 247, 0.4);
  backdrop-filter: blur(6.3px);
  z-index: 2;
}
#welcome .main .avatar .inner {
  background: white;
  display: flex;
  justify-content: center;
  width: 75%;
  height: 75%;
  z-index: 3;
  color: #33309F;
  font-size: min(18vw, 88px);
}
#welcome .main .avatar .owner_name {
  width: 100%;
  height: 20px;
  transform: translateY(10px);
  top: 100%;
  left: 0;
  font-size: 16px;
  text-align: center;
}
#welcome h1 {
  font-size: 32px;
  font-weight: 400;
  color: #414141;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  white-space: pre-line;
}
#welcome .hw {
  border: none;
  border-radius: 8px;
  margin: 30px auto 10px;
  max-width: 500px;
  max-height: 156px;
  overflow-y: scroll;
  padding: 15px 17px 20px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(6.3px);
  box-shadow: 0px 19px 62px -8px rgba(168, 166, 247, 0.4);
  display: flex;
  flex-flow: column;
}
#welcome .hw .hw_header {
  color: #a43a73;
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: end;
  margin-bottom: 25px;
}
#welcome .hw .hw_header h4 {
  font-weight: 700;
  margin: 0;
}
#welcome .hw .hw_header .counter {
  flex: 1 0 auto;
  display: block;
  position: relative;
}
#welcome .hw .hw_header .counter:after {
  content: attr(caption);
  display: block;
  position: absolute;
  width: 100%;
  font-size: 12px;
}
#welcome .hw .instructions {
  flex: 1 1 auto;
  overflow: scroll;
}
#welcome .fullname_text {
  width: calc(100% - 30px);
  max-width: 500px;
}
#welcome .not_me {
  color: #514DC7;
  text-decoration-color: #514DC7;
  display: inline-block;
  margin: 10px auto;
  width: auto;
}
#welcome .not_me .MuiSvgIcon-root {
  top: 5px;
  height: 18px;
  color: #514DC7;
  stroke-width: 1;
  stroke: #514DC7;
  position: relative;
}
#welcome .as_admin {
  position: absolute;
  bottom: 20px;
  font-size: 13px;
  left: 50%;
  transform: translateX(-50%);
  color: #33309F;
  font-weight: 500;
  cursor: pointer;
  cursor: hand;
}
#welcome .as_admin:after {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  bottom: -5px;
  background: linear-gradient(90deg, #33309F 50%, #8681F5 100%);
}
#welcome .MuiTextField-root.dir {
  margin-bottom: 20px;
}
body.rtl #welcome .MuiTextField-root.dir .MuiInputLabel-root {
  left: inherit !important;
  right: 0rem !important;
  transform-origin: right !important;
}

