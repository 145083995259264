.toolbar {
  z-index: 2000000;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.toolbar button, .toolbar input {
  pointer-events: all;
}
.toolbar input[type=range] {
  -webkit-appearance: none;
  appearance: none;
  height: 3px;
  width: 260px;
  border-radius: 5px;
  background: white;
  position: absolute;
  left: -110px;
  top: 50%;
  transform-origin: top center;
  transform: rotate(-90deg) translateY(-50%);
}
.toolbar input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  height: 16px;
  width: 16px;
  color: white;
  display: block;
  border-radius: 50%;
  margin-top: 0px;
}
.toolbar .sidebar {
  position: absolute;
  right: 0;
  top: 0;
  height: fit-content;
  display: flex;
  flex-flow: column;
  padding: 5px;
}
.toolbar .sidebar > div.switcher {
  background-color: rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
  overflow: visible;
  position: relative;
}
.toolbar .sidebar > div.switcher::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 130%;
  box-sizing: border-box;
  border-bottom: 2px solid white;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}
.toolbar .colorbar > div.picker {
  border: 2px solid white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}
.toolbar .colorbar > div.picker.selected {
  transform: scale(1.15);
  border: 4px solid white;
}
.toolbar .colorbar > div.switcher {
  background: rgba(0, 0, 0, 0.5) url("/public/img/icons/font_picker_icon.png") no-repeat center;
  background-size: 84%;
}
.toolbar .fontbar > div.switcher {
  background: rgba(0, 0, 0, 0.5) url("/public/img/icons/palette_icon.svg") no-repeat center;
  background-size: 70%;
}

