@import "Constants";
@import url('https://fonts.googleapis.com/css2?family=Rubik&family=Comic+Neue&family=Heebo&family=Comfortaa&family=Arimo&family=Amatic+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
$editor_button_size: 40px;

.App {
  text-align: center;
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: env(safe-area-inset-bottom, 0px);
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.playroom_container {
  min-height: 100%;
  position: relative;
  overflow: hidden;

  .vid & {
    min-height: 60%;
  }
  .focus.section_1 & {
    min-height: 0 !important;
    max-height: 0 !important;
    height: 0 !important;
  }

}
.video_container {
  max-height: 40%;
  background: white;
  position: relative;
  overflow: hidden;
  display: none;

  .vid & {
    display: block;
  }
  .focus.section_1 & {
    min-height: 100% !important;
    max-height: 100% !important;
    height: 100% !important;
  }

  &.fs {
    min-height: 100% !important;
    position: absolute;
    width: 100vw;
    height: auto !important;
    z-index: 1000;
    top: 0 !important;
    bottom: 0 !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.ui_screen {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 100;
}

.bottom_bar {
  $main_button_size: 60px;
  position:absolute;
  width: 100%;
  bottom: 0;
  height: 120px;
  pointer-events: none;
  color: white;
  text-align: center;
  padding-bottom: env(safe-area-inset-bottom, 0) !important;
  z-index: 9;

  > div {
    pointer-events: auto;
    background-color: rgba(0, 0, 0, 0.5);
    background-repeat: no-repeat;

    background-position: center;

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
    width: $main_button_size;
    height: $main_button_size;
    border-radius: 50%;

    &.add_card_button {
      background-color: $default_blue;
      transform: translate(-50%, -50%) scale(1.2);
      box-shadow: 0 4px 6px rgba(16, 40, 56, 0.7);

      &:after {
        position: absolute;
        content: "+";
        color: white;
        font-size: 46px;
        top: 50%;
        left: 50%;
        display: block;
        height: auto;
        font-family: Rubik;
        line-height: 28px;
        transform: translate(-50%, -50%);
        font-weight: bold;
      }

    }
  }
}

.editor_button {
  height: $editor_button_size;
  width: $editor_button_size;
  line-height: $editor_button_size - 2;
  font-size: 21px;
  margin: 5px;
  border-radius: 50%;
  box-sizing: border-box;
  pointer-events: auto;
  cursor: pointer;
  cursor: hand;
  -webkit-tap-highlight-color: transparent;
  background: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  color: white;
  text-align: center;

  &.selected {
    background-color: white;
    color: black;
  }
}

.scroller {
  overflow-y: scroll;
}

#manage_btn {
  position: absolute;
  top: 2px;
  right: 2px;
  height: 45px;
  line-height: 43px;
  width: 45px;
  z-index: 10;
  background-color: white;

  &:after {
    content: "☰";
    font-size: 25px;
    color: #3d3d3d;
  }
}

button.text_button {
  color: $default_blue;
  height: 36px;
  width: auto;
  outline: none;
  border: 1px solid $default_blue;
  box-sizing: border-box;
  background-color: white;
  border-radius: 18px;
  padding: 0 15px;
  font-size: 16px;
  font-weight: bold;

  &.default {
    background-color: $default_blue;
    color: white;
    border: none;
  }
  &.theme {
    background: $default_purple linear-gradient(90deg, #33309F 50%, #8681F5 100%);
    color: white;
    border: none;
  }

  &[disabled]{
    background-color: #a9a9a9;
  }
  &.full {
    width: 100%;
    max-width: $theme_max_width;
    margin: 10px auto 0;
    display: block;
  }
}
.hidden {
  display: none;
}

body {
  &.ltr {
    .dir, .MuiDialog-paper {
      direction: ltr;
    }
    .align {
      text-align: left;
    }
    .align-opp {
      text-align: right;
    }
  }
  &.rtl {
    .dir, .MuiDialog-paper {
      direction: rtl;
    }
    .align {
      text-align: right;
    }
    .align-opp {
      text-align: left;
    }
    .MuiList-root {
      direction: rtl;
    }
    .MuiButtonBase-root {
      font-size: 15px;
    }

  }
}

.center {
  position: absolute;
  height: auto;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bg_cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #0008;
  backdrop-filter: blur(2px);
  z-index: 8;

}

/* MUI */
.MuiTabs-root {
  background-color: white;
  min-height: 45px;
}

.MuiTabs-indicator {
  background-color: $default_blue;
}
.MuiButtonBase-root {
  padding: 0 0 0 0;
  min-height: 45px;
  font-size: 13px;

  &.MuiTab-root {
    &.Mui-selected {
      color: $default_blue;
    }
  }
}
.MuiSwitch-switchBase {
  padding: 9px;
  min-height: 0;
}

.MuiSnackbarContent-action {
  flex: 1 0 auto;
  margin: 0;
  padding: 0;
}

