#no_playroom {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;

  padding: 0 20px;
  box-sizing: border-box;

  h1 {
    font-weight: bold;
  }

  ul {
    padding: 0;
    margin-top: 40px;
    list-style-type: none;
    > li {
      margin-bottom: 10px;
    }
  }

  #bottom_bar {
    margin-top: 50px;
  }


  h1 {
    font-size: 35px;
  }
}
