@import "Constants";

.share_screen {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 10;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow: hidden;

  padding: 10px 15px;
  box-sizing: border-box;

  .close {
    position: absolute;
    top: 0px;
    left: auto;
    right: 10px;
    z-index: 10;
    @at-root body.rtl #{&} {
      left: 10px;
      right: auto;
    }
  }
  .qr {
    width: 180px;
    margin-top: 10px;
  }
  .MuiBox-root {
    width: 100%;
  }
  .MuiInput-input::selection, .MuiOutlinedInput-input::selection {
    background-color: $default_blue;
    color: white;
  }



  .title {
    .edit_icon {
      @at-root body.rtl #{&} {
        margin-left: 7px;
      }
      @at-root body.ltr #{&} {
        margin-right: 7px;
      }
    }
    .MuiInput-root {
      @at-root body.rtl #{&} {
        right: -30px;
        left: auto;
      }
      @at-root body.ltr #{&} {
        left: -30px;
        right: auto;
      }
    }
    .MuiInput-input {
      font-size: 32px;
      text-overflow: ellipsis;
      @at-root body.rtl #{&} {
        padding-right: 30px;
      }
      @at-root body.ltr #{&} {
        padding-left: 30px;
      }

    }
    .MuiInputLabel-root {

      font-size: 32px;
      transition: opacity 200ms cubic-bezier(0.0, 0, 0.2, 1), color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;

      @at-root body.ltr #{&} {
        right: auto;
        left: 0px;
        transform-origin: top left;
      }
      @at-root body.rtl #{&} {
        left: auto;
        right: 0px;
        transform-origin: top right;
      }


      &.MuiFormLabel-filled {

        @at-root body.rtl #{&} {
          transform: translate(30px, -1.5px) scale(0.5);
        }
        @at-root body.ltr #{&} {
          transform: translate(-30px, -1.5px) scale(0.5);
        }
        &:not(.Mui-focused) {
          opacity: 0;
        }

      }
      &.Mui-focused {
        @at-root body.rtl #{&} {
          transform: translate(30px, -1.5px) scale(0.5);
        }
        @at-root body.ltr #{&} {
          transform: translate(-30px, -1.5px) scale(0.5);
        }
      }
    }
  }

  .MuiOutlinedInput-root {
    border-radius: 5px;
    background-color: #f5f5f5;
  }
  #hw_expiration {
    font-size: 13px;
    padding: 5px 5px;
    box-sizing: border-box;

    a {
      //text-decoration: underline;
    }

    @at-root body.ltr #{&} {
      text-align: right;
    }
    @at-root body.rtl #{&} {
      text-align: left;
    }
  }
  .MuiOutlinedInput-input {
    @at-root body.ltr #{&} {
      direction: ltr;
    }
    @at-root body.rtl #{&} {
      direction: rtl;
    }
  }

  .instructions {
    .MuiInputLabel-root {
      @at-root body.rtl #{&} {
        right: 0;
        left: auto;
        transform-origin: top right;
        transform: translate(-14px, 16px) scale(1);
        &.Mui-focused {
          transform: translate(-14px, -9px) scale(0.75);
        }
        &.MuiFormLabel-filled {
          transform: translate(-14px, -9px) scale(0.75);
        }
      }
    }


    .MuiOutlinedInput-root {
        white-space: pre-wrap;

        .MuiOutlinedInput-notchedOutline {
          @at-root body.rtl #{&} {
            text-align: right;
            direction: rtl;
          }
        }
    }

  }
  #bottom_bar {
    flex: 1 1 auto;
    width: 100%;
    align-content: end;

    .MuiButtonBase-root {
      padding: 5px 20px;
      border-radius: 50px;
      background-color: $default_blue;
      font-weight: 700;
      font-family: Rubik;
      margin-bottom: 10px;
      .MuiButton-endIcon {
        margin-right: 0px;
        margin-left: 10px;
        @at-root body.rtl #{&} {
          margin-right: 10px;
          margin-left: 0px;
        }
      }
    }

  }

}