#playroom {
  position: absolute !important;
  width: 100%;
  height: 100%;
}
#playroom canvas {
  flex: 1;
  height: 100%;
  width: 100%;
  display: block;
}

#selection_bar {
  position: absolute;
  height: 150px;
  width: 100%;
  z-index: 10;
  background-color: white;
}

