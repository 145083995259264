.toolbar {
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
  z-index: 10;

}
.editor_toolbar {
  display: flex;
  flex-flow: row;
  direction: rtl;
}
.card_maker {
  .editor_button.camera_button {
    background-image: url('/public/img/icons/camera_icon.svg');
    background-repeat: no-repeat;
    background-size: 55%;
    background-position: center 45%;
  }
}
