@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Rubik&family=Comic+Neue&family=Heebo&family=Comfortaa&family=Arimo&family=Amatic+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
.App {
  text-align: center;
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: env(safe-area-inset-bottom, 0px);
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.playroom_container {
  min-height: 100%;
  position: relative;
  overflow: hidden;
}
.vid .playroom_container {
  min-height: 60%;
}
.focus.section_1 .playroom_container {
  min-height: 0 !important;
  max-height: 0 !important;
  height: 0 !important;
}

.video_container {
  max-height: 40%;
  background: white;
  position: relative;
  overflow: hidden;
  display: none;
}
.vid .video_container {
  display: block;
}
.focus.section_1 .video_container {
  min-height: 100% !important;
  max-height: 100% !important;
  height: 100% !important;
}
.video_container.fs {
  min-height: 100% !important;
  position: absolute;
  width: 100vw;
  height: auto !important;
  z-index: 1000;
  top: 0 !important;
  bottom: 0 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ui_screen {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 100;
}

.bottom_bar {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 120px;
  pointer-events: none;
  color: white;
  text-align: center;
  padding-bottom: env(safe-area-inset-bottom, 0) !important;
  z-index: 9;
}
.bottom_bar > div {
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.5);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.bottom_bar > div.add_card_button {
  background-color: #0F75BC;
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 0 4px 6px rgba(16, 40, 56, 0.7);
}
.bottom_bar > div.add_card_button:after {
  position: absolute;
  content: "+";
  color: white;
  font-size: 46px;
  top: 50%;
  left: 50%;
  display: block;
  height: auto;
  font-family: Rubik;
  line-height: 28px;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.editor_button {
  height: 40px;
  width: 40px;
  line-height: 38px;
  font-size: 21px;
  margin: 5px;
  border-radius: 50%;
  box-sizing: border-box;
  pointer-events: auto;
  cursor: pointer;
  cursor: hand;
  -webkit-tap-highlight-color: transparent;
  background: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  color: white;
  text-align: center;
}
.editor_button.selected {
  background-color: white;
  color: black;
}

.scroller {
  overflow-y: scroll;
}

#manage_btn {
  position: absolute;
  top: 2px;
  right: 2px;
  height: 45px;
  line-height: 43px;
  width: 45px;
  z-index: 10;
  background-color: white;
}
#manage_btn:after {
  content: "☰";
  font-size: 25px;
  color: #3d3d3d;
}

button.text_button {
  color: #0F75BC;
  height: 36px;
  width: auto;
  outline: none;
  border: 1px solid #0F75BC;
  box-sizing: border-box;
  background-color: white;
  border-radius: 18px;
  padding: 0 15px;
  font-size: 16px;
  font-weight: bold;
}
button.text_button.default {
  background-color: #0F75BC;
  color: white;
  border: none;
}
button.text_button.theme {
  background: #514DC7 linear-gradient(90deg, #33309F 50%, #8681F5 100%);
  color: white;
  border: none;
}
button.text_button[disabled] {
  background-color: #a9a9a9;
}
button.text_button.full {
  width: 100%;
  max-width: 500px;
  margin: 10px auto 0;
  display: block;
}

.hidden {
  display: none;
}

body.ltr .dir, body.ltr .MuiDialog-paper {
  direction: ltr;
}
body.ltr .align {
  text-align: left;
}
body.ltr .align-opp {
  text-align: right;
}
body.rtl .dir, body.rtl .MuiDialog-paper {
  direction: rtl;
}
body.rtl .align {
  text-align: right;
}
body.rtl .align-opp {
  text-align: left;
}
body.rtl .MuiList-root {
  direction: rtl;
}
body.rtl .MuiButtonBase-root {
  font-size: 15px;
}

.center {
  position: absolute;
  height: auto;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bg_cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5333333333);
  backdrop-filter: blur(2px);
  z-index: 8;
}

/* MUI */
.MuiTabs-root {
  background-color: white;
  min-height: 45px;
}

.MuiTabs-indicator {
  background-color: #0F75BC;
}

.MuiButtonBase-root {
  padding: 0 0 0 0;
  min-height: 45px;
  font-size: 13px;
}
.MuiButtonBase-root.MuiTab-root.Mui-selected {
  color: #0F75BC;
}

.MuiSwitch-switchBase {
  padding: 9px;
  min-height: 0;
}

.MuiSnackbarContent-action {
  flex: 1 0 auto;
  margin: 0;
  padding: 0;
}

