.draggable {
  --title-width: 40px;
  --frame-width: 10px;
  position: absolute;
  z-index: 100;
  width: 80%;
}
.draggable .handle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  box-sizing: border-box;
  border-radius: 10px;
  pointer-events: all;
}
.draggable .handle > div {
  position: absolute;
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
}
.draggable .handle > div.top {
  height: var(--title-width);
  width: 100%;
  top: 0;
  left: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.5333333333);
  border-left: 1px solid rgba(0, 0, 0, 0.5333333333);
  border-right: 1px solid rgba(0, 0, 0, 0.5333333333);
}
.draggable .handle > div.bottom {
  height: var(--frame-width);
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5333333333);
  border-left: 1px solid rgba(0, 0, 0, 0.5333333333);
  border-right: 1px solid rgba(0, 0, 0, 0.5333333333);
}
.draggable .handle > div.left {
  height: calc(100% - var(--title-width) - var(--frame-width));
  width: var(--frame-width);
  bottom: var(--frame-width);
  left: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.5333333333);
}
.draggable .handle > div.right {
  height: calc(100% - var(--title-width) - var(--frame-width));
  width: var(--frame-width);
  bottom: var(--frame-width);
  right: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.5333333333);
}
.draggable.focus {
  z-index: 10000;
}
.draggable.focus .handle {
  display: block;
}
.draggable .toolbar {
  position: absolute;
  width: 100%;
  height: var(--title-width);
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 20;
}
.draggable .toolbar > button {
  pointer-events: auto;
}
.draggable input, .draggable textarea, .draggable text-clone {
  position: relative;
  width: calc(100% - 2 * var(--frame-width) - 4px);
  z-index: 20;
  background-color: transparent;
  font-size: 20px;
  height: 20px;
  color: white;
  border: none;
  text-align: center;
  resize: none;
  outline: none;
  overflow: hidden;
  padding-top: 10px;
  font-weight: 600;
  margin: var(--title-width) var(--frame-width) var(--frame-width) var(--frame-width);
}

