@import "Constants";
#session_info {
  .scroller {
    flex: 1;
    padding-bottom: 60px;

    @media (min-width: $max_mobile_width) {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
    }
  }
}

.party_activity_title {
  color: white;
  height: 30px;
  width: 100%;
  margin: 10px 12px -5px;
  .small_select {
    direction: ltr;
    color: white;
    height: 25px;
    font-size: 18px;
    font-weight: bold;
    &::before {
      display: none !important;
    }
    .MuiSelect-select {


      @at-root body.rtl #{&} {
        padding-left: 28px !important;
        padding-right: 0px !important;

      }
      @at-root body.ltr #{&} {
        padding-right: 28px !important;
        padding-left: 0px !important;
      }
    }

    .MuiSvgIcon-root {
      color: white;
      fill: white;
      right: auto;
      @at-root body.rtl #{&} {
        left: 4px;
      }
      @at-root body.ltr #{&} {
        right: 4px;
      }
    }
  }
}
.MuiMenuItem-root {
  padding: 0 20px;
}
.card {
  --radius: 5%;
  aspect-ratio: calc(1080/1560);
  box-shadow: 0px 2px 4px rgba(122, 154, 182, 0.4), 0px 7px 13px -3px rgba(122, 154, 182, 0.3);
}
.info_panel {
  width: auto;
  margin: 14px 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 8px #0009;
  position: relative;

  .btn_delete {
    position: absolute;
    bottom: 0px;

    @at-root body.ltr #{&} {
      right: 0px;
    }
    @at-root body.rtl #{&} {
      left: 0px;
    }
  }

  @media (min-width: $max_mobile_width) {
    min-width: 350px;
  }
}
.party_activity_panel {
  padding: 10px 10px 10px;

  h2 {
    margin-top: 0;
    font-size: 18px;
    font-weight: 400;
  }

  > .selections {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(65px, 0.333fr)); // or 1fr
    gap: 4px;
    width: 100%;
    box-sizing: border-box;
    direction: ltr;
    > img.creation {
      width: 100%;
      border-radius: var(--radius) / calc(var(--radius) / 156 * 108);
    }

    > .selection {
      width: 100%;
      height: auto;
      position: relative;
      border-radius: var(--radius) / calc(var(--radius) / 156 * 108);
      > * {
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: var(--radius) / calc(var(--radius) / 156 * 108);
        box-sizing: border-box;
        border: 1px solid #cbcbcb;
      }
      > div {
        background-color: #e1e1e1;

      }
      @for $i from 1 through 2 {
        > *:nth-child(#{$i}) {
          top: #{calc(-2 * calc($i - 1))}px;
          left: #{calc(1 * calc($i - 1))}px;
        }
      }
      > img {
        top: -4px;
        left: 2px;
      }
    }

    > div.selection:after {
      content: attr(count);
      aspect-ratio: 1;
      width: 16px;
      line-height: 16px;
      background-color: $default_blue;
      color: white;
      font-weight: bold;
      position: absolute;
      font-size: 11px;
      display: block;
      padding: 2px;
      border-radius: 50%;
      right: 1px;
      top: -1px;
    }
    > img.selection {
      border-radius: var(--radius) / calc(var(--radius) / 156 * 108);
    }
  }

}
.card_info_panel {
  display: flex;
  gap: 15px;
  padding: 7px;

  > div {
    position: relative;

    &[type="user"] {
      padding-bottom: 16px;
    }
  }

  .card {
    height: 200px;
    border-radius: var(--radius) / calc(var(--radius) / 156 * 108);
  }
  p.card_source {
    font-size: 12px;
    margin: 0;
    padding: 1px 5px 0;
    position: absolute;
    max-width: calc(100% - 10px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .card_details {
    flex: 1 0;
    padding-top: 10px;

    ul {
      margin-top: 10px;
      width: calc(100% - 15px);
      list-style-type: "- ";
      list-style-position: inside;
      padding-right: 0;
      padding-left: 0;

      li {
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
      }
    }
    .more_selectors {
      width: 100%;

      box-sizing: border-box;

      @at-root body.ltr #{&} {
        text-align: right;
        padding-right: 10px;
      }
      @at-root body.rtl #{&} {
        text-align: left;
        padding-left: 10px;
      }
    }
    a {
      color: $default_blue
    }
  }
}
#session_info {
  background-color: $default_blue;

  .MuiTabs-flexContainer {
    background-color: #ebebeb;
  }

}
