.draggable {
  --title-width: 40px;
  --frame-width: 10px;
  position: absolute;
  z-index: 100;
  width: 80%;

  $handle_border-color: #0008;

  .handle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    box-sizing: border-box;
    border-radius: 10px;
    pointer-events: all;

    > div {
      position: absolute;
      backdrop-filter: blur(8px);
      background-color: rgba(255, 255, 255, 0.2);
      box-sizing: border-box;

      &.top {
        height: var(--title-width);
        width: 100%;
        top: 0;
        left: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-top: 1px solid $handle_border-color;
        border-left: 1px solid $handle_border-color;
        border-right: 1px solid $handle_border-color;
      }
      &.bottom {
        height: var(--frame-width);
        width: 100%;
        bottom: 0;
        left: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom: 1px solid $handle_border-color;
        border-left: 1px solid $handle_border-color;
        border-right: 1px solid $handle_border-color;
      }
      &.left {
        height: calc(100% - var(--title-width) - var(--frame-width));
        width: var(--frame-width);
        bottom: var(--frame-width);
        left: 0;
        border-left: 1px solid $handle_border-color;
      }
      &.right {
        height: calc(100% - var(--title-width) - var(--frame-width));
        width: var(--frame-width);
        bottom: var(--frame-width);
        right: 0;
        border-right: 1px solid $handle_border-color;
      }
    }

  }
  &.focus {
    z-index: 10000;
    .handle {
      display: block;
    }
  }


  .toolbar {
    position: absolute;
    width: 100%;
    height: var(--title-width);
    bottom: 0;
    left: 0;
    pointer-events: none;
    z-index: 20;
  }
  .toolbar > button {
    pointer-events: auto;
  }

  input, textarea, text-clone {
    position: relative;
    width: calc(100% - 2 * var(--frame-width) - 4px);
    z-index: 20;
    background-color: transparent;
    font-size: 20px;
    height: 20px;
    color: white;
    border: none;
    text-align: center;
    resize: none;
    outline: none;
    overflow: hidden;
    padding-top: 10px;

    //text-shadow: 0 0 5px #000;
    font-weight: 600;
    margin: var(--title-width) var(--frame-width) var(--frame-width) var(--frame-width);
  }
  input:focus, textarea:focus {

  }
}
