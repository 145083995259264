@import "Constants";

#menu_bar {
  height: $menu_bar_height;
  z-index: 3;
  box-shadow: 0 2px 4px #0000004f;


  h1 {
    font-size: 18px;
    font-family: Rubik;
    color: $default_blue;
    line-height: $menu_bar_height;
    height: $menu_bar_height;
    margin: 0;
    text-align: center;
    width: 100%;
  }

  &.emphasized {
    background-color: $default_blue;
    h1 {
      background-color: $default_blue;
      color: white;
    }
  }
}