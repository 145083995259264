.card_maker {
  --h: 100vh;
  --ar: calc(100vw / var(--h));
  --card-height: 1560;
  --card-width: 1080;
  --card-ar: calc(var(--card-width) / var(--card-height));
  --maker-width: min(calc(100vh * var(--card-ar)), 100vw);
  height: 100%;
  max-height: 100vh;
  width: var(--maker-width);
  background-color: black;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
.card_maker img {
  width: var(--maker-width);
  height: calc(var(--maker-width) / var(--card-ar));
  object-fit: cover;
  transform: scaleX(-1);
}
.card_maker img.flipped {
  transform: none;
}
.card_maker #content {
  position: relative;
  overflow: hidden;
}
.card_maker > button {
  position: absolute;
  top: calc(100vh - 50px);
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
}
.card_maker .bottom_bar > div.send_button {
  background-image: url("/public/img/icons/send_icon.webp");
  background-size: 47%;
  background-repeat: no-repeat;
  background-position: 46% 56%;
  background-color: #0F75BC;
  right: 0;
  left: auto;
  transform: translate(-50%, -50%) rotate(45deg);
}
.card_maker #close {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.card_maker #render_preloader {
  color: white;
  font-size: 30px;
  position: absolute;
  z-index: 100;
  width: 100%;
  top: 50%;
  font-weight: bold;
}
.card_maker .camera_component {
  height: 100%;
  width: 100%;
  position: relative;
}
.card_maker .camera_component input[type=file] {
  display: none;
}
.card_maker .camera_component .bottom_bar > div.flip_button {
  right: 20px;
  left: auto;
  transform: translateY(-50%);
  background-image: url("/public/img/icons/flip_camera_icon.svg");
  background-size: 70%;
  transition: transform 0.3s;
}
.card_maker .camera_component .bottom_bar > div.flip_button :hover {
  transform: translateY(-50%) rotate(180deg);
}
.card_maker .camera_component .bottom_bar > div.gallery_button {
  left: 20px;
  transform: translateY(-50%);
  background-image: url("/public/img/icons/gallery_icon.png");
  background-size: 57%;
}
.card_maker .camera_component .bottom_bar > div.capture_button {
  background-color: white;
  width: 60.5px;
  height: 60.5px;
  box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.2666666667);
}
.card_maker .camera_component .bottom_bar > div.capture_button:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 2px solid white;
  transform: scale(1.3);
  border-radius: 50%;
  pointer-events: none;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2666666667);
}
.card_maker .camera_component > div:first-child {
  box-sizing: border-box;
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.0666666667);
  z-index: 8;
  width: var(--maker-width);
  height: calc(var(--maker-width) / var(--card-ar));
}
.card_maker .camera_component > div:first-child > div:first-child {
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
}
.card_maker .camera_component > div:first-child > div:first-child video {
  height: calc(var(--maker-width) / var(--card-ar));
  width: var(--maker-width);
}
.card_maker .camera_component .camera_warning {
  position: absolute;
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
  z-index: 100;
  color: white;
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card_maker .camera_component .camera_popup {
  position: absolute;
  padding: 15px;
  width: calc(100% - 20px);
  max-width: 400px;
  margin: 15px auto;
  box-sizing: border-box;
  background-color: white;
  border-radius: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 120;
}

