.gutter {
  background-color: white;
  position: relative;
  filter: drop-shadow(0 0px 8px #0008);
  perspective: 100px;
  perspective-origin: center;
  height: 10px;
  margin-bottom: -9px;
  display: none;

  .vid & {
    display: block;
  }
  .focus & {
    display: none !important;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 50px;
    height: 18px;
    bottom: 7px;
    background-color: white;
    left: 50%;
    transform: translateX(-50%) perspective(70px) rotateX(50deg);
    border-radius: 7px 7px 0 0;
  }

  &:before {
    content: "V";
    position: absolute;
    color: #737373;
    z-index: 2000;
    top: -12px;
    transform: translateX(-50%) scaleY(0.5) scaleX(2);
    font-family: Rubik;

  }
  .minimized & {
    &:before {
      transform: translateX(-50%) scaleY(-0.5) scaleX(2);
    }
  }
}

.minimizable {
  .minimized & {
    transition: height 0.5s;
  }
}