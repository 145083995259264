.settings_menu {
  .MuiListItemButton-root {
    padding: 8px 16px;
  }
  .MuiList-padding {
    padding: 8px 0;
  }

  .MuiListItemButton-root, .MuiListItem-root {
    flex-direction: row-reverse;
  }
  .MuiListItemIcon-root {
    //justify-content: end;
  }
  .MuiListItemText-root {
    text-align: right;
  }

}